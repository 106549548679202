import { View, Text, ScrollView } from "../../components/ui"
import { Training } from '../../models/TrainingData'
import KitInfoView from './KitInfoView'
import TrainingInfoView from './TrainingInfoView'
import { Linking, Platform } from "react-native"
import ValidateEmail from '../ui/ValidateEmail'
import LabInfoView from './LabInfoView'
import { FilesView } from '../FilesView'
import { H2, H3, useDripsyTheme } from "dripsy"
import TestConnectionView from './TestConnectionView'
import { WebViewer } from '../WebViewer';
import HubModal from '../ui/HubModal';
import { NotesView } from '../NotesView'
import InfoIcon from "svg/info-icon-blue-left.svg"
import { TrackGoogleAnalyticsEvent } from '../../utils/GoogleAnalytics';
import { useState } from "react"
import { useConfig } from "../../config/useConfig"
import { IconText } from "../ui/IconText"

const courseMaterialIcon = require('../../../assets/training/course-material-icon.png')
const labAccessIcon = require('../../../assets/training/lab-access-icon.png')
const receiptIcon = require('../../../assets/training/receipt-icon.png')
const notesIcon = require('../../../assets/training/notes-icon.png')

const imvpTestConnectionIcon = require('../../../assets/training/imvp-test-connection-icon.png')
const classSurveyIcon = require('../../../assets/training/class-survey-icon.png')
const classCommentsIcon = require('../../../assets/training/class-comments-icon.png')
const FilesIcon = require('../../../assets/training/files-icon.png')

const launchImvpIcon = require('../../../assets/training/launch-imvp-icon.png')
const recordingIcon = require('../../../assets/training/class-recordings.png')
const headphoneIcon = require('../../../assets/training/headphones-mic.png')

interface TrainingDetailsProp {
    training: Training
    onEmailValidation: () => {}
}

export default function TrainingDetails(props: TrainingDetailsProp) {
    const { training, onEmailValidation } = props
    const [currentModalContent, setCurrentModalContent] = useState<React.ReactElement>();
    const [modalVisible, setModalVisible] = useState(false);
    const { theme } = useDripsyTheme()
    const env = useConfig()

    const LaunchClass = ({ training }: { training: Training }) => {

        var username_and_password = (training.imvpMeetingId && training.imvpPassword) ? true : false;
        var trainingLink = (training.virtualJoinUrl ? training.virtualJoinUrl : training.imvpLaunchUrlExternal)
        if (username_and_password) trainingLink = trainingLink.substring(0, 21) + (trainingLink.length > 21 ? '...' : '');

        return (
            <View>
                <View sx={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', flexWrap: 'wrap' }}>
                    {(training.imvpLaunchUrlExternal.length > 0 || training.virtualJoinUrl.length > 0) && (
                        <IconText icon={launchImvpIcon} textColor='$primary' last={true} onAction={() => Linking.openURL(`${training.virtualJoinUrl ? training.virtualJoinUrl : training.imvpLaunchUrlExternal}`)} underline={true} text="Join Your Live Virtual Class" />
                    )}
                    <View sx={{ flexDirection: 'row', marginBottom: '$1' }}>
                        {training.imvpPlatform.length > 0 && <Text sx={{ marginLeft: '$3', padding: '$2', borderRightColor: '$primary', borderRightWidth: 1, color: '$primary' }}>Platform: <Text sx={{ fontWeight: 'bold' }}>{training.imvpPlatform}</Text></Text>}
                        {training.imvpMeetingId.length > 0 && <Text sx={{ padding: '$2', borderRightColor: '$primary', borderRightWidth: 1, color: '$primary' }}>Meeting ID: <Text sx={{ fontWeight: 'bold' }}>{training.imvpMeetingId}</Text></Text>}
                        {training.imvpPassword.length > 0 && <Text sx={{ padding: '$2', color: '$primary' }}>Password: <Text sx={{ fontWeight: 'bold' }}>{training.imvpPassword}</Text></Text>}
                    </View>
                </View>
                {training.imvpLaunchNote.length !== 0 && <View sx={{ bg: '$highlight', padding: '$3' }}><Text sx={{ color: '$primary' }}>{training.imvpLaunchNote}</Text></View>}
            </View>
        )
    }

    const Sessions = ({ training }: { training: Training }) => {
        return (training.sessions && training.sessions.length > 0 && <>
            <View>
                <View sx={{ alignItems: 'center' }}>
                    <View sx={{ paddingY: '$3', flexDirection: 'row', alignItems: 'center' }}>
                        <InfoIcon style={{ width: 30 }} />
                        <Text sx={{ fontWeight: 'bold', marginLeft: '$2', color: '$primary' }}>Event Sessions</Text>
                    </View>
                </View>
                <View>
                    <View sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '$gray' }}>
                        <Text sx={{ fontSize: 13, color: '$primary', paddingY: '$3', paddingX: '$3', fontWeight: 'bold', flexBasis: '30%' }}>Session Number</Text>
                        <Text sx={{ fontSize: 13, color: '$primary', paddingY: '$3', paddingLeft: '$3', borderColor: '$mediumgray', borderLeftWidth: 1, fontWeight: 'bold', flexBasis: '35%' }}>Date</Text>
                        <Text sx={{ fontSize: 13, color: '$primary', paddingY: '$3', paddingLeft: '$3', borderColor: '$mediumgray', borderLeftWidth: 1, fontWeight: 'bold', flexBasis: '35%' }}>Time</Text>
                    </View>
                    {training.sessions.map((session, index) => (
                        <View key={index} sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', borderColor: '$mediumgray', borderBottomWidth: 1 }}>
                            <Text sx={{ fontSize: 13, color: '$primary', paddingY: 16, paddingX: '$3', flexBasis: '30%', fontWeight: 'bold' }}>Session {index + 1}</Text>
                            <Text sx={{ fontSize: 13, color: '$primary', paddingY: 16, paddingLeft: '$3', flexBasis: '35%' }}>{session.date}</Text>
                            <Text sx={{ fontSize: 13, color: '$primary', paddingY: 16, paddingLeft: '$3', flexBasis: '35%' }}>{session.time}</Text>
                        </View>
                    ))}
                </View>
            </View>
        </>)
    }

    const PreClassView = ({ training }: { training: Training }) => (
        <View>
            <View sx={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', flexWrap: Platform.OS === 'web' ? 'wrap' : 'nowrap' }}>
                {training.connectionTest.length > 0 && <IconText icon={imvpTestConnectionIcon} onAction={() => setupTestConnectionModal(training)} text="Connection Test" />}
                {training.surveyStartClassUrl.length !== 0 && <IconText icon={classSurveyIcon} onAction={() => Linking.openURL(`${training.surveyStartClassUrl}`)} text="Student Background Questionnaire" />}
            </View>
        </View>
    )

    const DuringClassView = ({ training }: { training: Training }) => {
        return (
            <View>
                <View sx={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', flexWrap: Platform.OS === 'web' ? 'wrap' : 'nowrap' }}>
                    {(training.publicNote.length > 0 || training.privateNote.length > 0 || training.instructorNote.length > 0) && <IconText icon={classCommentsIcon} last={true} text="Class Information" onAction={() => setupInfoModal(training)} />}
                    {training.kitInfo.show && <IconText icon={courseMaterialIcon} onAction={() => setupKitInfoModal(training)} text="Course Kit" />}
                    {training.surveyCollectorUrl.length > 0 && <IconText icon={classSurveyIcon} onAction={() => { Linking.openURL(training.surveyCollectorUrl) }} text={training.program ? "Mid-Program Evaluation" : "Mid-Class Evaluation"} />}
                    {training.labInfo.show && <IconText icon={labAccessIcon} text="Lab Access" onAction={() => setupLabInfoModal(training)} />}
                    {training.noteFiles.length > 0 && <IconText icon={notesIcon} text="Notes" onAction={() => setupNotesModal(training)} />}
                    {training.files.length > 0 && <IconText icon={FilesIcon} text="Files" onAction={() => setupFilesModal(training)} />}
                </View>
            </View>
        )
    }

    const PostClassView = ({ training }: { training: Training }) => {
        var showRec = false;
        if ((training.recordingOverride && training.recordingOverride.length !== 0) || (training.recordingNote && training.recordingNote.length !== 0)) {
            showRec = training.showRecording;
        }
        return (
            <View>
                <View sx={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', flexWrap: Platform.OS === 'web' ? 'wrap' : 'nowrap' }}>
                    {training.surveyEndClassUrl.length !== 0 &&
                        <IconText
                            icon={classSurveyIcon}
                            onAction={() => { setupEndClassSurveyModal(training) }}
                            text={training.program ? "Final Program Evaluation" : "Final Class Evaluation"} />
                    }
                    {training.certificationUrlExternal.length !== 0 && <IconText icon={classSurveyIcon} onAction={() => training.certificationUrl === 'AVAIL' ? setupValidateEmailModal() : OpenCertificate(training)} text="Completion Certificate" />}
                    {training.certificationInstructions.length !== 0 && <IconText icon={classSurveyIcon} onAction={() => training.certificationInstructions === 'AVAIL' ? setupValidateEmailModal() : OpenCertificateModal(training)} text="Completion Certificate" />}
                    {training.receiptUrl.length !== 0 && env.brand === 'exitcertified' && <IconText icon={receiptIcon} onAction={() => training.receiptUrl === 'AVAIL' ? setupValidateEmailModal() : Linking.openURL(`${env.webBase}/${training.receiptUrl}`)} text="Receipt" />}
                    {showRec && <IconText icon={recordingIcon} onAction={() => setupClassRecordingModal(training)} text="Class Recording" />}
                </View>
            </View>
        )
    }

    const OpenCertificate = (training: any) => {
        TrackGoogleAnalyticsEvent("certificate", "DownloadCertificate", "Downloaded a Completion Certificate", "xhr");
        Linking.openURL(`${training.certificationUrlExternal}`);
    }

    const OpenCertificateModal = (training: any) => {
        TrackGoogleAnalyticsEvent("certificate", "ViewCertificate", "Viewed a Completion Certificate", "beacon");
        setupCertModal(training.certificationInstructions);
    }

    function setupLabInfoModal(training: Training) {
        setCurrentModalContent(<LabInfoView labInfo={training.labInfo} />);
        setModalVisible(true);
    }

    function setupKitInfoModal(training: Training) {
        setCurrentModalContent(<KitInfoView kitInfo={training.kitInfo} />);
        setModalVisible(true);
    }

    function setupInfoModal(training: Training) {
        setCurrentModalContent(<TrainingInfoView training={training} />);
        setModalVisible(true);
    }

    function setupFilesModal(training: Training) {
        setCurrentModalContent(<FilesView files={training.files} title="Files" scrollable={true} />);
        setModalVisible(true);
    }

    function setupNotesModal(training: Training) {
        setCurrentModalContent(<NotesView files={training.noteFiles} />);
        setModalVisible(true);
    }

    async function setupValidateEmailModal() {
        setCurrentModalContent(<ValidateEmail onSuccess={finishEmailValidation} />)
        setModalVisible(true);
    }

    async function setupTestConnectionModal(training: Training) {
        setCurrentModalContent(<TestConnectionView opportunityId={training.id} />)
        setModalVisible(true);
    }

    function setupClassRecordingModal(training: Training) {
        setCurrentModalContent(Rec(training))
        setModalVisible(true);
    }

    const Rec = (training: any) => {
        var note = training.recordingOverride.length !== 0 ? training.recordingOverride : training.recordingNote;
        return <ScrollView>
            <H2 sx={theme.styles.header}>Class Recording</H2>
            <View sx={{ alignItems: 'flex-start', paddingBottom: '$5', paddingX: '$3' }}>
                <WebViewer html={note} heightDivision={2}></WebViewer>
            </View>
        </ScrollView>
    }

    const Survey = (training: any) => {
        return <ScrollView>
            <H2 sx={theme.styles.header}>Class Survey</H2>
            <View sx={{ alignItems: 'flex-start', paddingBottom: '$5', paddingX: '$3' }}>
                <WebViewer html={training.surveyEndClassUrl} heightDivision={2}></WebViewer>
            </View>
        </ScrollView>
    }

    async function setupEndClassSurveyModal(training: Training) {
        if (training.surveyEndClassUrl.startsWith('https://') || training.surveyEndClassUrl.startsWith('http://')) {
            Linking.openURL(`${training.surveyEndClassUrl}`)
        } else if (training.surveyEndClassUrl == 'auto') {
            var content = (
                <>
                    <H2 sx={theme.styles.header}>{training.program ? "Final Program Evaluation" : "Final Class Evaluation"}</H2>
                    <View sx={{ alignItems: 'flex-start', paddingBottom: '$5', paddingX: '$3' }}>
                        <Text>Final training evaluation instructions will be here at the end of the training.</Text>
                    </View>
                </>);
            setCurrentModalContent(content)
            setModalVisible(true)
        } else {
            setCurrentModalContent(Survey(training))
            setModalVisible(true)
        }
    }

    const setupCertModal = (certificationInstructions: string) => {
        const content = (
            <>
                <H3>Completion Certificate</H3>
                <View sx={{ alignItems: 'flex-start', paddingBottom: '$5', paddingX: '$3' }}>
                    <WebViewer html={certificationInstructions} heightDivision={2}></WebViewer>
                </View>
            </>)

        setCurrentModalContent(content)
        setModalVisible(true)
    }

    async function finishEmailValidation() {
        setModalVisible(false)
        onEmailValidation()
    }

    return (
        <>
            {training.studentHubEnabled && (
                <>
                    <View sx={{ width: '100%', minHeight: 130 }}>
                        <LaunchClass training={training} />
                        <PreClassView training={training} />
                        <DuringClassView training={training} />
                        <PostClassView training={training} />
                    </View>
                    <Sessions training={training} />
                </>
            )}
            {currentModalContent && <HubModal
                modalLoading={false}
                modalVisible={modalVisible}
                ModalContent={currentModalContent}
                setModalVisible={setModalVisible} />
            }
        </>
    )
}