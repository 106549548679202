import { useEffect, useState } from 'react';
import { H2, View, ActivityIndicator, Text, ScrollView } from '../components/ui';
import { useTraining } from '../hooks/useTraining';
import { Event } from '../models/Event';
import { TrainingMgmtScreenProps } from '../navigation/types';
import CourseInfo from '../components/training/CourseInfo';
import { Linking, Platform } from 'react-native';
import InfoButton from '../components/ui/InfoButton';
import HubModal from '../components/ui/HubModal';
import RosterView from '../components/management/RosterView';
import { useTrainingMgmt } from '../hooks/useTrainingMgmt';
import { useDripsyTheme } from 'dripsy';
import { TrainingMgmt } from '../models/TrainingMgmtData';
import { useNavigation } from '@react-navigation/native';
import { AntDesign } from '@expo/vector-icons';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import UploadRosterView from '../components/management/UploadRosterView';
import MgmtFilesView from '../components/management/MgmtFilesView';
import TestConnectionView from '../components/training/TestConnectionView';

export default function TrainingMgmtProgramScreen({ route }: TrainingMgmtScreenProps<'TrainingProgram'>) {
    const navigation = useNavigation<NativeStackNavigationProp<any>>()
    const { getProgramEvents } = useTraining()
    const { trainingMgmtData } = useTrainingMgmt()
    const [loading, setLoading] = useState(false)
    const [programEvents, setProgramEvents] = useState<Event[]>([])
    const [modalVisible, setModalVisible] = useState(false)
    const [training, setTraining] = useState<TrainingMgmt>()
    const [currentModalContent, setCurrentModalContent] = useState<React.ReactElement>()
    const { theme } = useDripsyTheme()

    useEffect(() => {
        async function loadAsync() {
            try {
                setLoading(true)
                const pevents = await getProgramEvents(route.params.id)
                setProgramEvents(pevents)
            } catch (err: any) {
                console.warn(err.toString())
            } finally {
                setLoading(false)
            }
        }

        loadAsync()
    }, [route.params.id])

    useEffect(() => {
        if (trainingMgmtData) {
            const allTraining = [...trainingMgmtData.active, ...trainingMgmtData.future, ...trainingMgmtData.past]
            for (const i in allTraining) {
                let tempTraining = allTraining[i]
                if (tempTraining.reggieId === Number(route.params.id)) {
                    setTraining(tempTraining)
                    break;
                }
            }
        }
    }, [trainingMgmtData, route.params.id])

    const showModal = (content: React.ReactElement) => {
        setCurrentModalContent(content)
        setModalVisible(true)
    }

    const showModalView = (event: Event) => {
        const t = event.training ? event.training : training;
        if (t) {
            showModal(<RosterView training={t} event={event} />);
        }
    }

    const showUploadRoster = (event: Event) => {
        const t = event.training ? event.training : training;
        if (t) {
            showModal(<UploadRosterView training={t} event={event} />)
        }
    }

    const showFilesModal = (eventId: number, parentEventId: number) => {
        showModal(<MgmtFilesView eventId={eventId} parentEventId={parentEventId} />)
    }

    const showTestConnectionView = (trainingMgmt?: TrainingMgmt) => {
        if (trainingMgmt) {
            showModal(<TestConnectionView opportunityId={trainingMgmt.id} />);
        }
    }

    function goBack() {
        navigation.canGoBack() ? navigation.goBack() : navigation.navigate('TrainingView')
    }

    function isRosterEditable(event: Event): boolean {
        if (event.training) {
            return (!event.training.isDuplicate && event.training.rosterEditable)
        }

        if (training) {
            return (!training.isDuplicate && training.rosterEditable)
        }

        return false
    }

    return (
        <View sx={{ flex: 1, padding: '$2', paddingTop: 0, backgroundColor: '#fff' }}>
            <View sx={{ marginTop: '$3', marginLeft: '$3', ...theme.layout.viewBack }}>
                <AntDesign sx={{ cursor: 'pointer' }} name={"left"} size={28} color={theme.colors.$primary} />
                <Text sx={{ marginTop: 7, cursor: 'pointer', color: 'rgb(0, 85, 140)', fontWeight: 600 }} onPress={() => { goBack() }}>Back</Text>
            </View>
            <H2 sx={theme.styles.header}>Program Classes</H2>
            {(loading || training === undefined) ? (<ActivityIndicator sx={{ marginTop: '$4' }} size="large" color="$primary" />) : (
                <ScrollView>
                    {programEvents.filter((event) => event.id != training.reggieId).map((event, i) => (
                        <View key={event.id}>
                            <CourseInfo training={event} tableView={true} />
                            <View sx={{ bg: '$gray', marginBottom: '$2' }}>
                                <View sx={{ flexDirection: Platform.OS === 'web' ? 'row' : 'column', justifyContent: 'center', alignItems: 'center', flexWrap: Platform.OS === 'web' ? 'wrap' : 'nowrap' }}>
                                    {event.id > 0 &&
                                        <>
                                            <InfoButton text="Manage Roster" onPress={() => showModalView(event)} />
                                            {isRosterEditable(event) &&
                                                <InfoButton text="Upload Roster" onPress={() => { showUploadRoster(event) }} />
                                            }
                                            <InfoButton text="Files" onPress={() => showFilesModal(event.id, event.parentEventId)} />
                                            {event.training && (<>
                                                {event.training.connectionTest && event.training.connectionTest.length > 0 &&
                                                    <InfoButton text="Complete Connection Test" onPress={() => { showTestConnectionView(event.training) }} />
                                                }
                                            </>
                                            )}
                                            {event.showEvaluations && event.surveyPostClassReportLink &&
                                                <InfoButton text="Class Evaluation Report" onPress={() => {
                                                    event.surveyPostClassReportLink && Linking.openURL(event.surveyPostClassReportLink)
                                                }} />
                                            }
                                        </>
                                    }
                                </View>
                            </View>
                        </View>
                    ))}
                </ScrollView>
            )}
            {currentModalContent && <HubModal
                modalLoading={false}
                modalVisible={modalVisible}
                ModalContent={currentModalContent}
                setModalVisible={setModalVisible} />
            }
        </View>
    )
}