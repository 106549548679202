import TrainingDataView from '../TrainingDataView'
import { H2, H3, Text, View, ActivityIndicator } from '../../components/ui'
import { useTrainingInstructor } from "../../hooks/useTrainingInstructor";
import { TrainingInstructor } from '../../models/TrainingInstructorData';
import { Linking, Platform } from 'react-native';
import { TrainingMgmt } from '../../models/TrainingMgmtData';
import { Training } from '../../models/TrainingData';
import { Section } from '../../models/Section';
import { Pressable } from '../ui/Pressable';
import { MaterialIcons, AntDesign } from '@expo/vector-icons';
import { useDripsyTheme } from 'dripsy';
import { useMember } from '../../hooks/useMember';
import ValidateEmail from '../ui/ValidateEmail';
import React, { useEffect, useState } from 'react';
import TrainingFilters from '../../components/ui/TrainingFilters';
import HubModal from '../ui/HubModal';
import TrainingView from '../training/TrainingView';
import TrainingProvider from '../../providers/TrainingProvider';
import TrainingInstructorFiles from "../instructor/TrainingInstructorFiles";
import { useMediaQuery } from "react-responsive";
import InfoButton from '../ui/InfoButton';

export default function TrainingInstructorView() {
    const { member, setHeader, showHeader } = useMember()
    const { loading, trainingInstructorData, trainingInstructorFiles, refreshTrainingInstructorData } = useTrainingInstructor()
    const { theme } = useDripsyTheme()
    const [modalVisible, setModalVisible] = useState(false)
    const [currentModalContent, setCurrentModalContent] = useState<React.ReactElement>()
    const [showInstructorHeader, setShowInstructorHeader] = useState(true);
    const isMobile = useMediaQuery({ query: '(max-width: 680px)' })
    const isContractor = (member?.instructorType == 'contractor' || member?.instructorType == 'contractor_broker');

    useEffect(() => {
        async function loadContactTraining() {
            if (Platform.OS === 'web') {
                if (window.location.search) {
                    const params = new URLSearchParams(window.location.search)
                    const contactID = params.get('contactID')
                    const reggieID = params.get('reggieID')

                    if (contactID && reggieID && member?.emailValidated) {
                        setCurrentModalContent(<TrainingProvider><TrainingView /></TrainingProvider>);
                        setModalVisible(true)
                    }
                }
            }
        }
        loadContactTraining()
    }, [])

    useEffect(() => {
        setHeader(false);
    }, [showHeader])

    const Content = ({ training, section }: { training: Training | TrainingMgmt | TrainingInstructor, section: Section }) => {
        const trainingInstructor = training as TrainingInstructor;
        return <View sx={{ bg: '$gray', marginBottom: '$2', alignItems: 'center' }}>
            {(trainingInstructor.sessionCount > 0) && (
                <Text sx={{ textAlign: 'center', margin: '$2', marginBottom: '$0', fontStyle: 'italic' }}>See Event Information Page for specific session dates and times.</Text>
            )}
            <View sx={{ flexDirection: Platform.OS === 'web' ? 'row' : 'column', justifyContent: 'center', alignItems: 'flex-start', flexWrap: Platform.OS === 'web' ? 'wrap' : 'nowrap' }}>
                {trainingInstructor.surveyPostClassReportLink.length > 0 &&
                    <InfoButton text="Class Evaluation Report" onPress={() => { Linking.openURL(trainingInstructor.surveyPostClassReportLink) }} />
                }
                {(trainingInstructor.sessionCount > 0) || (trainingInstructor.eventInfo && trainingInstructor.eventInfoUrl.length > 0) ? (
                    <InfoButton text="Instructor Event Information" onPress={() => Linking.openURL(trainingInstructor.eventInfoUrl)} />
                ) : trainingInstructor.regType != 'Vacation' && trainingInstructor.regType != 'Stat. Holiday' && trainingInstructor.surveyPostClassReportLink.length == 0 && (
                    <Text sx={{ marginY: '$3' }}>Event Info Not Available</Text>
                )}
                {isContractor && (trainingInstructor.dailyCompensationRate.length > 0 || trainingInstructor.totalContractorCost.length > 0) &&
                    <InfoButton text="Compensation" onPress={() => showCompensation(trainingInstructor)} />
                }
            </View>
        </View>
    }

    const showCompensation = (training: TrainingInstructor) => {
        setCurrentModalContent(<Compensation training={training} />);
        setModalVisible(true)
    }

    const Compensation = ({ training }: { training: TrainingInstructor }) => {
        return (<View sx={{ maxHeight: '100%', overflow: 'hidden', alignItems: 'center' }}>
            <View sx={{ alignItems: 'center', padding: '$2', flexDirection: 'column' }}>
                <H2 sx={theme.styles.header}>Compensation</H2>
                <Text sx={{ marginBottom: 12 }}>
                    The following agreement details are provided for assistance with your invoicing, please see your booking agreement for full details.
                </Text>
                <View sx={{ alignItems: 'left', padding: '$2', flexDirection: 'column', width: '100%' }}>
                    <Text sx={{ marginBottom: 6 }}>Reggie ID: {training.id}</Text>
                    {training?.dailyCompensationRate && <Text sx={{ marginBottom: 6 }}>Daily Rate ($): {training.dailyCompensationRate}</Text>}
                    {training?.totalContractorCost && <Text sx={{ marginBottom: 6 }}>Total Contractor Cost ($): {training.totalContractorCost}</Text>}
                </View>
            </View>
        </View>)
    }

    const FooterContent = () => {
        return null
    }

    const InfoMessage = () => {
        return <View sx={{ maxHeight: showInstructorHeader ? '100%' : 0, overflow: 'hidden', alignItems: isContractor ? 'flex-end' : isMobile ? 'center' : 'flex-start' }}>
            {isContractor && <View sx={{ alignItems: 'center', padding: '$2', flexDirection: 'row' }}>
                <MaterialIcons name="info-outline" sx={{ width: '8%' }} size={24} color="white" />
                <Text sx={{ marginLeft: '$2', width: '92%', color: 'white', fontWeight: 'bold', fontSize: '$0', textDecorationLine: 'underline' }}>
                    These events are not confirmed until the displayed Status is Confirmed and you receive a confirmation email from the Instructor Management team.
                    For any tentative classes, we will be in touch with a status update by two weeks before the class date.
                    If you receive another request for the same date, please let us know.
                </Text>
            </View>
            }
        </View>
    }

    const ToggleInstructorHeader = () => {
        setShowInstructorHeader(showInstructorHeader ? false : true);
    }

    return (
        <>
            <View sx={{ flexDirection: 'column', backgroundColor: '$primary', paddingY: '$3', paddingX: '$3', marginX: '$2', borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}>
                <View>
                    <H3 sx={{ color: '#fff', fontSize: '$1', textAlign: 'left', marginY: 0 }}>Training Instructors</H3>
                    <Pressable sx={{ position: 'absolute', right: 0, left: 0, width: '100%', margin: 0, padding: 0, marginTop: -3, height: 19, flexDirection: 'row', alignItems: 'center' }} onPress={ToggleInstructorHeader}>
                        <AntDesign style={{ width: '100%', textAlign: 'right' }} name={!showInstructorHeader ? "up" : "down"} size={24} color={theme.colors.$background} />
                    </Pressable>
                </View>
                {(showInstructorHeader &&
                    <View sx={{ maxHeight: '100%', overflow: 'hidden' }}>
                        {(isContractor) && (<InfoMessage />)}
                        <View sx={{ alignItems: 'center', width: '100%', marginX: 0, flex: isMobile && (trainingInstructorFiles && trainingInstructorFiles.length > 0) ? '40px' : 'auto', flexDirection: 'row', margin: '$3', marginBottom: '$2', justifyContent: 'space-between' }}>
                            <TrainingInstructorFiles />
                            {Platform.OS === 'web' && <TrainingFilters />}
                        </View>
                    </View>
                )}
            </View>

            {member?.emailValidated ? (
                <View sx={{ flex: 1, padding: '$2', backgroundColor: '#fff' }}>
                    {!trainingInstructorData || loading ? (<ActivityIndicator sx={{ marginTop: '$4' }} size="large" color="$primary" />) : (
                        <TrainingDataView
                            trainingData={trainingInstructorData}
                            loading={loading}
                            showSections={true}
                            onRefreshTraining={refreshTrainingInstructorData}
                            ItemContent={Content}
                            FooterContent={FooterContent}
                        />
                    )}
                </View>
            ) : (
                <ValidateEmail customMsg='Validate your email to view the instructor classes' />
            )}

            <HubModal
                modalVisible={modalVisible}
                modalLoading={false}
                ModalContent={currentModalContent}
                setModalVisible={setModalVisible}
            />

        </>
    )
}