import { TrainingInstructorFile } from '../../models/TrainingInstructorData';
import { H2, H3, Text, View, ScrollView } from '../../components/ui'
import { useMemberApi } from "../../api/useMemberApi";
import { useTrainingInstructor } from "../../hooks/useTrainingInstructor";
import { Dimensions } from 'react-native';
import { MaterialIcons, AntDesign } from '@expo/vector-icons';
import { useRestUtils } from "../../api/useRestUtils";
import { useConfig } from "../../config/useConfig";
import { useState } from 'react';
import { Platform } from "react-native";
import { Pressable } from '../ui/Pressable';
import { P, useDripsyTheme } from 'dripsy';
import * as FileSystem from 'expo-file-system';
import { useMediaQuery } from "react-responsive";
import { shareAsync } from 'expo-sharing';
import { saveAs } from '@progress/kendo-file-saver';
import HubModal from '../ui/HubModal';

const { StorageAccessFramework } = FileSystem;


export default function TrainingInstructorFiles() {
    const { downloadInstructorFile } = useMemberApi()
    const { trainingInstructorFiles } = useTrainingInstructor()
    const [modalVisible, setModalVisible] = useState(false)
    const { headers } = useRestUtils()
    const { apiBase } = useConfig()
    const { theme } = useDripsyTheme()
    const windowSize: any = Dimensions.get('window').height - 200;
    const isMobile = useMediaQuery({ query: '(max-width: 400px)' });
    const fileStyle = {
        paddingBottom: theme.space.$5,
        paddingX: theme.space.$3,
        maxHeight: windowSize,
        minWidth: theme.sizes.labMinWidth,
        width: '100%'
    }

    const GetInstructorFile = async (subfile: TrainingInstructorFile) => {
        if (Platform.OS == 'ios' || Platform.OS == 'android') {
            var options = { camelCaseKeys: true, jsonBody: false, download: false }
            const result = await FileSystem.downloadAsync(`${apiBase}/management/instructorFileDownload/${subfile.hash}`,
                FileSystem.documentDirectory + subfile.name, {
                headers: headers(options)
            });
            if (Platform.OS == 'android') {
                const permisions = await FileSystem.StorageAccessFramework.requestDirectoryPermissionsAsync();
                if (permisions.granted) {
                    const base64 = await FileSystem.readAsStringAsync(result.uri, { encoding: FileSystem.EncodingType.Base64 })
                    await FileSystem.StorageAccessFramework.createFileAsync(permisions.directoryUri, subfile.name, result.headers['Content-Type'])
                        .then(async (uri: string) => {
                            await FileSystem.writeAsStringAsync(uri, base64, { encoding: FileSystem.EncodingType.Base64 });
                        }).catch(e => console.log(e));
                }
            } else {
                shareAsync(result.uri);
            }
        } else {
            downloadInstructorFile(subfile.hash).then(response => response.blob()).then(async (res) => {
                saveAs(res, subfile.name);
            });
        }
    }

    const InstructorFileView = () => {
        return <>
            <ScrollView sx={fileStyle}>
                <H2 sx={theme.styles.header}>Additional Resources</H2>
                {trainingInstructorFiles && trainingInstructorFiles.length > 0 &&
                    <View sx={{ alignItems: 'center' }}>
                        <View sx={{ width: '100%', maxWidth: 560 }}>
                            {trainingInstructorFiles.map((file, index) => (
                                <View key={index} sx={{ marginBottom: 12 }}>
                                    <H3 sx={{ marginBottom: '$3' }}>{file.title}</H3>
                                    <Text sx={{ marginBottom: 15, whiteSpace: 'pre-line' }}>{file.description}</Text>
                                    <View sx={{
                                        flexDirection: 'row', justifyContent: 'space-between', paddingVertical: '$2',
                                        paddingHorizontal: '$3', alignItems: 'center', backgroundColor: '$primary'
                                    }}>
                                        <Text sx={{ fontSize: 14, color: '#FFF', fontWeight: 'bold', paddingRight: '$5', flexBasis: '80%' }}>Name</Text>
                                        <Text sx={{ fontSize: 14, color: '#FFF', fontWeight: 'bold', paddingRight: '$5', flexBasis: '20%' }}></Text>
                                    </View>
                                    {file.files && file.files.map((subfile, index) => (
                                        <View key={index} sx={{
                                            flexDirection: 'row', justifyContent: 'space-between', paddingVertical: '$2',
                                            paddingHorizontal: '$3', alignItems: 'center', backgroundColor: '$gray',
                                            borderLeftWidth: 1, borderColor: '$primary', borderBottomWidth: 1, borderRightWidth: 1
                                        }}>
                                            <Text sx={{ fontSize: 14, color: '$primary', flexBasis: '80%' }}>{subfile.name}</Text>
                                            <View sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexBasis: '15%' }}>
                                                <Pressable onPress={() => GetInstructorFile(subfile)}>
                                                    <AntDesign name="clouddownloado" size={32} color={theme.colors.$primary} />
                                                </Pressable>
                                            </View>
                                        </View>
                                    ))}
                                </View>
                            ))}
                        </View>
                    </View>
                }
            </ScrollView>
        </>
    }

    return trainingInstructorFiles && trainingInstructorFiles?.length > 0 &&
        <>
            <Pressable sx={{ paddingX: isMobile ? 7 : '$3', paddingY: '$2', bg: '$background', flexDirection: 'row', alignItems: 'center' }}
                onPress={() => setModalVisible(true)}>
                <MaterialIcons name="info-outline" size={24} color={theme.colors.$primary} />
                <Text sx={{ color: '$primary', fontWeight: 'bold', marginLeft: '$2' }}>Additional Resources</Text>
            </Pressable >
            <HubModal
                modalVisible={modalVisible}
                modalLoading={false}
                ModalContent={<InstructorFileView />}
                setModalVisible={setModalVisible}
            />
        </>
}