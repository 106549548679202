
import { Platform, Dimensions, ScrollView } from "react-native";
import { RosterInvitee, TrainingMgmt } from "../../models/TrainingMgmtData";
import { useTraining } from '../../hooks/useTraining';
import { Text, H2, View, ActivityIndicator } from "../../components/ui";
import { useDropzone } from "react-dropzone";
import { CSSProperties, useCallback, useMemo, useEffect, useState } from "react";
import { useTrainingMgmt } from "../../hooks/useTrainingMgmt";
import { ValidateEmail, mergeArraysToMap, formatName, formatEmail, EmailUsername } from "../../utils/Common";
import { useDripsyTheme } from "dripsy";
import { Pressable } from "../ui/Pressable";
import BrandEmailLink from "../ui/BrandEmailLink";
import { ProgressBar } from "react-native-paper";
import { Event } from "../../models/Event";

interface UploadRosterViewProps {
    training: TrainingMgmt
    event?: Event
}

interface Invitee {
    firstname: string,
    lastname: string,
    email: string
}

export default function UploadRosterView(props: UploadRosterViewProps) {
    const { training, event } = props
    const { updateRoster, fetchRoster, roster } = useTrainingMgmt()
    const { getProgramEvents } = useTraining()
    const [programEvents, setProgramEvents] = useState<Event[]>([])
    const [loading, setLoading] = useState(false)
    const [windowSize, setWindowSize] = useState(0)
    const [csvData, setCsvData] = useState<Invitee[]>([])
    const [badCsvData, setBadCsvData] = useState<{ [key: string]: string }[]>([])
    const [finished, setFinished] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [progressCount, setProgressCount] = useState(0)
    const { theme } = useDripsyTheme()

    useEffect(() => {
        setWindowSize(Dimensions.get('window').height - 200)
        async function loadAsync() {
            try {
                setLoading(true)
                await fetchRoster(training.id, event?.id)
                if (training.reggieId && training.program && !event) {
                    const pevents = await getProgramEvents(training.reggieId)
                    setProgramEvents(pevents)
                }
            } catch (err: any) {
                console.warn(err.toString())
            } finally {
                setLoading(false)
            }
        }
        if (!training.isDuplicate) {
            loadAsync()
        }
    }, [])

    const EmailExists = (email: string, roster: RosterInvitee[]) => {
        var exists = false;
        roster.forEach((entry) => {
            if (entry && email && email == entry.email) {
                exists = true;
            }
        });
        return exists;
    }

    function validateData(data: { [key: string]: string }, roster: RosterInvitee[]): [boolean, string] {
        let error = ""
        let res = true

        const params: string[] = ['email', 'firstname', 'lastname']

        if (data.firstname || data.lastname || data.email) {
            params.forEach((field) => {
                if (field in data) {
                    if (field === 'email') {
                        if (!ValidateEmail(data[field])) {
                            error = "bad email format"
                            res = false
                        }
                        if (EmailExists(data[field], roster)) {
                            error = "already added to the roster."
                            res = false
                        }
                    }

                    if (data[field] === "" || data[field] === undefined) {
                        error = `${field} cannot be empty`
                        res = false
                    }

                } else {
                    error = `missing ${field} field`
                    res = false
                }
            })
        }

        return [res, error]
    }

    const convertToCsv = (data: string | null | ArrayBuffer, roster: RosterInvitee[]) => {
        let parsedata: Invitee[] = []
        let baddata: { [key: string]: string }[] = []
        setCsvData([]);
        setBadCsvData([]);
        if (data && typeof data === "string") {

            let keys: string[] = []
            let newLinebrk = data.split("\n");
            for (let i = 0; i < newLinebrk.length; i++) {
                const str = newLinebrk[i]
                if (str.replace(/,/g, "").length > 1) {
                    const ss = str.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/, -1)
                    if (i === 0) {
                        keys = ss.map((key, i) => (key.replace(/[^a-z0-9]/gi, '').toLocaleLowerCase()))
                    } else {
                        let mergedData = null
                        try {
                            mergedData = mergeArraysToMap(keys, ss)
                        } catch (err: any) {
                            continue
                        }

                        if (mergedData) {
                            if (mergedData.firstname) mergedData.firstname = formatName(mergedData.firstname);
                            if (mergedData.lastname) mergedData.lastname = formatName(mergedData.lastname);
                            if (mergedData.email) mergedData.email = formatEmail(mergedData.email);
                            const [result, msg] = validateData(mergedData, roster)
                            if (result) {
                                const invitee: Invitee = { firstname: mergedData.firstname, lastname: mergedData.lastname, email: mergedData.email }
                                parsedata.push(invitee)
                            } else {
                                mergedData['error'] = msg
                                baddata.push(mergedData)
                            }
                        }
                    }
                }
            }
            if ((parsedata.length + roster.length) > training.rosterMax) {
                setBadCsvData([{ 'firstname': '', 'lastname': '', 'email': '', 'error': 'maximum' }]);
            } else {
                setCsvData(parsedata)
                setBadCsvData(baddata)
            }

        }
    }

    async function onUpload() {
        setSubmitting(true)
        try {
            let count = 0
            let eventId = 0
            
            if (event) {
                eventId = event.id
            } else if (training.program) {
                eventId = training.reggieId
            }

            for (const invitee of csvData) {
                await updateRoster(training.id, invitee.firstname, invitee.lastname, invitee.email, eventId)
                setProgressCount(count += 1);
            }

            if (training.program && !event) {
                for (const programEvent of programEvents) {
                    for (const invitee of csvData) {
                        await updateRoster(training.id, invitee.firstname, invitee.lastname, invitee.email, programEvent?.id, true)
                        setProgressCount(count += 1);
                    }
                }
            }

            setTimeout(() => {
                setFinished(true)
                setSubmitting(false)
            }, 1000);

        } catch (err: any) {
            alert(err.toString())
        }
    }

    const onDrop = useCallback((files: File[], roster: RosterInvitee[]) => {
        files.forEach((file: File) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                convertToCsv(reader.result, roster)
            }
            reader.readAsText(file)
        })
    }, [])

    const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        onDrop: (acceptedFiles: File[]) => {
            onDrop(acceptedFiles, roster);
        },
        accept: { 'text/csv': [] },
        maxFiles: 1
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const displayRoster = csvData.map((invitee: Invitee, index) => (
        <li key={index} style={{ ...listyle }}>
            <span>{index + 1}. {invitee.firstname} {invitee.lastname} &lt;{invitee.email}&gt;</span>
            <span style={{ color: '#5cb85c', textTransform: 'uppercase', fontSize: 10 }}>Valid</span>
        </li>
    ))

    const progressFull = programEvents.length > 0 ? (programEvents.length * csvData.length) + csvData.length : csvData.length;
    const progressInteger = Math.round((progressCount / progressFull) * 100)

    const DisplayErrors = () => (
        <ul style={{ ...ulstyle, borderColor: theme.colors.$error, display: badCsvData.length == 1 ? 'flex' : 'block' }}>
            {badCsvData.map((data: { [key: string]: string }, index) => (
                <li key={index} style={{ ...listyle, color: theme.colors.$error, display: badCsvData.length == 1 ? 'block' : 'flex' }}>
                    {data['error'] == 'maximum' ? (
                        <Text sx={{ fontSize: 12, fontWeight: 'bold', color: theme.colors.$error }}>Adding these students to the class will exceed the classes maximum size, please email <BrandEmailLink style={{ color: theme.colors.$error, fontSize: 12 }} username={EmailUsername(training.coordination)} /> to increase the maximum class size.</Text>
                    ) : (
                        <>
                            <span>
                                {badCsvData.length > 1 ? (index + 1) + '.' : ''} {data['firstname'] && data['firstname']} {data['lastname'] && data['lastname']}
                                {data['email'] && <span>&nbsp;&lt;{data['email']}&gt;</span>}
                            </span>
                            <span style={{ textTransform: 'uppercase', fontSize: data['email'] ? 10 : 12, float: data['email'] ? 'right' : 'left' }}>{data['error']}</span>
                        </>
                    )}
                </li>
            ))}
        </ul>
    )

    if (finished) {
        return (
            <ScrollView style={{ height: '100%', width: '100%' }}>
                <H2 sx={theme.styles.header}>Upload Roster</H2>
                <Text sx={{ bg: '$success', color: '#fff', paddingY: '$3', marginBottom: '$2', fontWeight: 'bold', textTransform: 'uppercase', textAlign: 'center' }}>Successfully updated roster</Text>
            </ScrollView>
        )
    }

    return (
        <ScrollView automaticallyAdjustKeyboardInsets={true} style={{ paddingBottom: theme.space.$5, paddingHorizontal: theme.space.$3, maxHeight: windowSize, width: '100%' }}>
            <H2 sx={theme.styles.header}>Upload Roster</H2>
            <Text sx={{ color: '$text', textAlign: 'center' }}>For the CSV please use headers containing "FirstName", "LastName" and "Email"</Text>
            {loading ? (
                <ActivityIndicator sx={{ marginTop: '$4' }} size="large" color="$primary" />
            ) : (Platform.OS === 'web' ? (
                (roster.length <= training.rosterMax - 1) ?
                    <>
                        <section className="container" style={{ padding: 10 }}>
                            <div {...getRootProps({ className: 'dropzone', style })}>
                                <input {...getInputProps()} />
                                <p style={{ cursor: 'pointer' }}>Drag 'n' drop a CSV file here, or click to select a CSV file</p>
                            </div>
                            <aside>

                                {badCsvData.length > 0 &&
                                    <div>
                                        <span style={{ color: theme.colors.$error, fontWeight: 'bold', paddingLeft: 5, textTransform: 'uppercase' }}>Issues found</span>
                                        <DisplayErrors />
                                    </div>
                                }

                                {csvData.length > 0 &&
                                    <div>
                                        <span style={{ fontWeight: 'bold', paddingLeft: 5, textTransform: 'uppercase' }}>Entries</span>
                                        <ul style={{ ...ulstyle }}>
                                            {displayRoster}
                                        </ul>
                                        <View sx={{ flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                            <Text sx={{ color: '$primary' }}>{submitting ? 'Submitting...' : 'Submit valid results'}</Text>
                                            {badCsvData.length > 0 && <Text sx={{ color: '$primary' }}>OR, fix your issues and re-upload the file</Text>}
                                            {submitting && <>
                                                <ProgressBar progress={progressInteger / 100} color={theme.colors.$primary} style={{ width: 200, marginVertical: theme.space.$3 }} />
                                                <Text>{progressInteger}% Complete</Text>
                                            </>}
                                            {progressInteger < 100 &&
                                                <Pressable onPress={onUpload} version="buttons.primary">
                                                    <Text sx={{ color: '#fff', fontWeight: 'bold' }}>Submit</Text>
                                                </Pressable>
                                            }
                                        </View>
                                    </div>
                                }

                            </aside>
                        </section>
                    </>
                    :
                    <>
                        <Text sx={{ marginTop: 25, marginBottom: 19, textAlign: 'center' }}>This event is full, please email <BrandEmailLink style={{ color: theme.colors.$primary }} username={EmailUsername(training.coordination)} /> to request to increase the class capacity.</Text>
                    </>
            ) : (
                <Text>Not Currently Implemented</Text>
            )
            )}
        </ScrollView>
    )
}

const ulstyle: CSSProperties = {
    listStyleType: "none",
    margin: 0,
    marginBottom: 10,
    padding: 0,
    // borderWidth: 1,
    // borderStyle: 'solid',
    // borderRadius: 4,
    width: '100%',
    boxSizing: 'border-box'
}

const listyle: CSSProperties = {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 12,
    fontWeight: 'bold',
    borderBottomColor: '#ececec',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid'
}

const baseStyle: CSSProperties = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    marginBottom: 10,
    justifyContent: 'center'
}

const focusedStyle = {
    borderColor: '#2196f3'
}

const acceptStyle = {
    borderColor: '#00e676'
}

const rejectStyle = {
    borderColor: '#ff1744'
}